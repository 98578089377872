<template>
  <div>
    <div class="pititle">
      <div class="zhidingsearch">
        <el-form>
          <div class="litop">
            <el-row>
              <el-col :span="5">
                <div class="grid-content bg-purple">
                  <el-form-item label="文件名称">
                    <el-input
                      v-model="name"
                      placeholder="请输入您的标准名称"
                    ></el-input>
                  </el-form-item>
                </div>
              </el-col>

              <el-col :span="5">
                <div class="grid-content bg-purple"></div>
              </el-col>
              <el-col :span="5"> </el-col>
              <el-col :span="5"> </el-col>
              <el-col :span="4">
                <div class="grid-content bg-purple">
                  <el-button type="primary" @click="search">查询</el-button>
                  <el-button type="info" @click="qingkong">清空</el-button>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-form>
      </div>
      <div class="piconetn">
        <div
          style="
            margin-bottom: 10px;
            display: flex;
            flex-direction: row-reverse;
          "
        >
          <el-button type="success" style="height: 45px" @click="piliangload"
            >批量下载</el-button
          >
        </div>
        <!-- 表格 -->
        <div class="pitable">
          <el-table
            :data="tableData"
            :row-class-name="tabRowClassName"
            style="width: 100%"
            :header-cell-style="{ background: '#3082e2', color: '#FFF' }"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column
              label="序号"
              type="index"
              width="150px"
              align="center"
            ></el-table-column>
            <el-table-column label="文件名称" prop="name" align="center">
              <template slot-scope="{ row }">
                <div
                  class="linkto"
                  @click="zhanshi(row)"
                >
                  <span>{{ row.name }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="上传时间" align="center" width="150px">
              <template slot-scope="{ row }">
                <span>{{ row.updated_at | formatDate }}</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="150px">
              <template slot-scope="{ row }">
                <!-- <a :href="row.url" :download="row.name"><i class="el-icon-download"></i></a> -->
                <i
                  class="el-icon-download"
                  style="color: #67c23a; cursor: pointer"
                  @click="dowloawd(row.fileName, row.url)"
                ></i>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <!-- 分页 -->
        <div class="pageTotal">
          <div class="block">
            <el-pagination
              background
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="page"
              layout="prev, pager, next"
              :total="totalCount"
              :page-size="limit"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="政策文件上传" :visible.sync="dialogVisible" width="30%">
      <el-form ref="fileform" :model="form" label-width="80px">
        <el-form-item label="文件名称">
          <div class="wenjian">
            <div class="shangchuan">
              <el-input v-model="form.filename"></el-input>
            </div>
            <div class="shwj">
              <el-upload
                :data="{
                  token: $store.state.token,
                }"
                :file-list="materImgList"
                :on-success="
                  (response, file, fileList) =>
                    changeMaterImg(1, response, file, fileList)
                "
                :show-file-list="false"
                action="api/public/bupload"
                class="upload-demo"
              >
                <el-button size="small" type="primary" style="height: 40px"
                  >上传文件
                </el-button>
              </el-upload>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { zcfilelist, addfiles } from "@api/project";
import axios from "axios";
export default {
  filters: {
    formatDate: function (timestamp, fmt) {
      if (fmt == undefined || fmt == "" || fmt == null) {
        fmt = "yyyy-MM-dd";
      }
      if (!timestamp) {
        return "";
      }
      let date = new Date(timestamp);
      // 第一步先替换年份 因为年份跟其他不一样是四位
      // let pat =
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, date.getFullYear());
      }
      // 第二步匹配其他，然后替换
      let obj = {
        "M+": date.getMonth() + 1,
        "d+": date.getDate(),
        "h+": date.getHours(),
        "m+": date.getMinutes(),
        "s+": date.getSeconds(),
      };
      for (let key in obj) {
        let pat = `(${key})`;
        if (new RegExp(pat).test(fmt)) {
          let str = obj[key] + "";
          // RegExp.$1  MM dd hh mm ss贪婪匹配
          fmt = fmt.replace(RegExp.$1, ("00" + str).substr(-2));
        }
      }
      return fmt;
    },
  },
  data() {
    return {
      //选中的表格数据
      selectedProjects: [],
      dialogVisible: false,
      form: {},
      fileform: {},
      page: 1,
      limit: 10,
      totalCount: 0,
      totalPage: 1,
      tableData: [],
      status: "",
      token: "",
      firstUnit: "",
      name: "",
      zhidingType: "",
      biaozhunType: "",
      materImgList: [],
    };
  },
  mounted() {
    // this.getList();
    this.getToken();
  },
  methods: {
    zhanshi(row) {
      console.log("2222");
      // this.dialogfiles = true;
      // this.viewFile(row.url, row.name)
      window.open(row.url);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    downloadfile(fileName, url) {
      // let files = url;
      // let download_files = [];
      // if (files && files.length > 0) {
      //   download_files.push({ url: url });
      //   if (download_files && download_files.length > 0) {
      //     for (let i = 0; i < download_files.length; i++) {
      //       console.log("1111", download_files[i].url);
      //       let link = document.createElement("a");
      //       link.style.display = "none";
      //       link.href = download_files[i].url;
      //       link.setAttribute("download", fileName);
      //       document.body.appendChild(link);
      //       link.click();
      //       document.body.removeChild(link); //下载完成移除元素
      //       window.URL.revokeObjectURL(url); //释放掉blob对象
      //     }
      //   } else {
      //     this.$message.warning("没有文件可下载！");
      //   }
      // } else {
      //   this.$message.warning("没有文件可下载！");
      // }
      axios
        .get(url, { responseType: "blob", params: {} })
        .then((res) => {
      
          if (!res.data) {
            this.$message.warning(fileName + "下载失败！");
            return;
          }
          if (typeof window.navigator.msSaveBlob !== "undefined") {
            window.navigator.msSaveBlob(new Blob([res.data]), fileName);
          } else {
            let url = window.URL.createObjectURL(new Blob([res.data]));
            let link = document.createElement("a");
            link.style.display = "none";
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link); //下载完成移除元素
            window.URL.revokeObjectURL(url); //释放掉blob对象
          }
        })
        .catch((response) => {
          this.$message.warning(fileName + "下载失败！");
        });
    },
    dowloawd(fileName, url) {
      this.downloadfile(fileName, url);
    },
    piliangload() {
      this.multipleSelection.forEach((item) => {
        this.downloadfile(item.name, item.url);
      });
    },
    addmit() {
      let params = {
        sid: "public",
        type: "99",
        files: this.materImgList,
        token: this.$store.state.token,
      };
      addfiles(params).then((res) => {
        console.console.log("上传", res);
        this.dialogVisible = false;
        this.$message({
          message: "上传成功",
          type: "success",
        });
      });
    },
    changeMaterImg(index, response, file, fileList) {
      this.materImgList.push(response.data);
      this.form.filename = response.data.name;
    },
    getToken() {
      this.token = this.$store.state.token; //获取cookie字符串
      console.log(this.$store.state);
      this.getList();
    },
    getList() {
      // debugger
      let data = {
        limit: this.limit,
        page: this.page,
        token: this.token,
        type: "99",
        name: this.name,
      };
      zcfilelist(data).then((rec) => {
        // console.log('打印返回的数据111', rec)
        this.tableData = rec.data.data;
        this.limit = rec.data.per_page;
        this.page = rec.data.current_page;
        this.totalCount = rec.data.total;
        this.totalPage = rec.data.total;
      });
    },

    tabRowClassName({ rowIndex }) {
      let index = rowIndex + 1;
      if (index % 2 == 0) {
        return "warning-row";
      }
    },
    gotoStep(row) {
      let url = "/project/stepInformation?id=" + row.id + "&active=";
      if (row.status < 3 || row.status == 15 || row.status == 16) {
        url += 0;
      } else if (row.status < 7 || row.status == 17 || row.status == 18) {
        url += 1;
      } else if (row.status < 12) {
        url += 2;
      } else if (row.status < 13) {
        url += 3;
      } else if (row.status < 14) {
        url += 4;
      } else {
        url += 5;
      }
      return url;
    },

    //搜索
    search() {
      this.page = 1;
      this.getList();
    },
    //清空
    qingkong() {
      (this.firstUnit = ""),
        (this.name = ""),
        (this.zhidingType = ""),
        (this.biaozhunType = ""),
        this.getList();
    },
    // 分页
    handleSizeChange() {
      // this.limit = page;
      // this.getList();
      // console.log(`每页 ${page} 条`)
    },
    handleCurrentChange(val) {
      //console.log(`当前页: ${page}`)
      this.page = val;
      this.getList();
    },
    //表格多选
    selected_change(val) {
      this.selectedProjects = val;
    },
  },
};
</script>
<style scoped>
/deep/.el-button--primary {
  background-color: #3082e2;
  border-color: #3082e2;
}
/deep/.el-button--primary:hover {
  background-color: #409eff;
  border-color: #409eff;
}
/deep/.el-input__suffix {
  right: 43px;
  transition: all 0.3s;
  border-left: 1px solid #c0c4cc;
}
/deep/.el-table__row .el-table__row--striped {
  background-color: rgba(48, 130, 226, 0.1);
}
/deep/ .el-table th.el-table__cell > .cell {
  min-width: 100px;
}
/deep/ .el-table th.el-table__cell.is-leaf,
/deep/ .el-table td.el-table__cell {
  border-bottom: 0;
}
/deep/.el-form-item__label {
  font-family: Adobe Heiti Std;
  color: #2d2d2d;
}
/deep/ .litop .el-input {
  width: 245px;
}

/deep/ .litop .el-input__inner {
  width: 204px;
}

/deep/ .el-table th.el-table__cell > .cell {
  font-size: 16px;
}
/deep/.el-table .warning-row {
  background: rgba(48, 130, 226, 0.1);
}
.pitable {
  /*height: 60vh;*/
}
/deep/.jieduan .el-select {
  width: 90%;
}
/deep/.jieduan .el-input__suffix {
  right: 2px;
}
.litop {
  margin: 20px 24px;
}
.wenjian {
  display: flex;
  flex-direction: row;
}
.shangchuan {
  width: 70%;
}
.shwj {
  margin-left: 10px;
}
.litop span {
  color: #3086fb;
  margin-left: 10px;
  line-height: 40px;
}

.pageTotal {
  display: flex;
  flex-direction: row-reverse;
  margin: 38px 0;
}
.prbtn {
  display: flex;
  flex-direction: row-reverse;
}
.linkto {
  text-decoration: none;
  color: #3082e2;
  cursor: pointer;
}
.linkto > span:hover {
  color: #84b5ec;
}
/* 新改 */
.zhidingsearch {
  background-color: #ffffff;
  padding: 1px 15px;
  margin: 20px 0 10px 0;
}
.piconetn {
  background-color: #ffffff;
  padding: 20px;
  box-shadow: 0px 3px 9px 1px #eeeeee;
}
</style>
